import React from 'react';
import DashboardGallery from '@tra-sg/gatsby-theme-c360-portal/src/components/DashboardGallery'
import PageWrapper from '@tra-sg/gatsby-theme-c360-portal/src/components/PageWrapper';
import { Location } from "@reach/router";

export default function CampaignGallery({}) {
  return (
    <PageWrapper
      activeTab="surveys"
      metaTitle="Surveys | c360"
    >
      <Location>
        {({ location }) => (
          <DashboardGallery
            group="survey"
            location={location}
          />
        )}
      </Location>
    </PageWrapper>
  )
}
